<template>
  <a-dropdown :trigger="['click']" v-model="show">
    <div slot="overlay">
      <a-spin :spinning="loading">
        <a-tabs class="dropdown-tabs" :tabBarStyle="{textAlign: 'center'}" :style="{width: '297px'}">
          <a-tab-pane >
            <p style="font-size: 16px;font-weight: 500;border-bottom:2px solid rgba(0,0,0,.1);padding-bottom: 10px;text-align: center;">
              通知  <a-button type="link"  style="position: absolute;right: 0;" @click="msgCountClick('')">全部已读</a-button>
            </p>
            <a-list class="tab-pane" v-if="dataSource.length > 0" >
              <a-list-item v-for="item in dataSource" :key="item.id" style="display: flex;flex-direction: column;text-align: left;position: relative;cursor: pointer;">
                <div style="width: 100%;" @click="msgCountClick(item)">
                  <p style="font-size: 16px;font-weight: 500;">{{ item.sourceName }}</p>
                  <p >{{ item.messageContent }}</p>
                   <span style="font-size: 14px;">{{ item.remindTime }}</span>
                   <a-badge v-if="item.status == 2" style="position: absolute;right: 0;top: 10px;" class="notice-badge" count="1" > </a-badge>
                </div>
              </a-list-item>
          
              <p style="cursor: pointer;text-align: center;margin-top: 10px;" v-if="hasMore" @click="fetchMoreData">加载更多</p>
              <p style="text-align: center;margin-top: 10px;" v-else>已加载全部消息</p>
            </a-list>
          </a-tab-pane>
          <a-list  class="tab-pane" v-if="dataSource.length == 0">
              <a-list-item>
                <a-list class="tab-pane"></a-list>
              </a-list-item>
          </a-list>
        </a-tabs>
      </a-spin>  
    </div>
    <span @click="fetchNotice" class="header-notice">
      <a-badge class="notice-badge" :count="msgCount">
        <a-icon :class="['header-notice-icon']" type="bell" />
      </a-badge>
    </span>
  </a-dropdown>
</template>

<script>
import { request } from '@/utils/request';

export default {
  name: 'HeaderNotice',
  data () {
    return {
      loading: false,
      show: false,
      dataSource:[],
      hasMore: true,
      page: 0, // 当前页码
      size: 20, // 每页数量
      msgCount:0,//未读数
    }
  },
  mounted() {
    this.getData();
    setInterval(()=>{
      this.page = 0;
      this.getData();
    },1000 * 60)
  },
  methods: {
    
    msgCountClick(item){
      this.loading = true
      if(item) {
        // 单挑已读
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/messagerecord/updateStatus/'+item.id, 'get').then(res=>{
          if(res.data.code == 200) {
            this.page = 0
            this.getData()
          }
        })
      } 
      if(!item) {
        // 全部已读
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/messagerecord/updateRead', 'post',{}).then(res=>{
          if(res.data.code == 200) {
            this.page = 0
            this.getData()
          }
        })
      }
    },
    getCount() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/messagerecord/countUnread', 'get').then(res => {
        this.msgCount = res.data.data
      })
    },
    getData(){
      this.loading = true;
      if(this.page == 0) {
        this.dataSource = []
      }
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/messagerecord/findAll', 'post',{page: this.page, size: this.size}).then(res => {
        this.loading = false;
        if (res.data.code == 200) {
          this.dataSource = this.dataSource.concat(res.data.data.data);
          if (res.data.data.data.length < this.size) {
            this.hasMore = false;
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
      this.getCount();//未读数
    },
    fetchNotice () {
      if (this.loading) {
        this.loading = false
        return
      }
      if (this.show) return
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    fetchMoreData() {
      if (!this.hasMore) {
        return;
      }
      this.page++;
      this.getData();
    }
  }
}
</script>

<style lang="less">
  .header-notice{
    display: inline-block;
    transition: all 0.3s;
    span {
      vertical-align: initial;
    }
    .notice-badge{
      color: inherit;
      .header-notice-icon{
        font-size: 16px;
        padding: 4px;
      }
    }
  }
  .dropdown-tabs{
    background-color: @base-bg-color;
    box-shadow: 0 2px 8px @shadow-color;
    border-radius: 4px;
    .tab-pane{
      padding: 0 24px 12px;
      max-height: 250px;
      max-height: 500px;
      overflow-y: scroll;
    }
  }


::v-deep .ant-tabs-bar {
  border: none !important;
}

::v-deep .ant-tabs-nav-container {
  display: none !important;
}

.dropdown-tabs .ant-tabs-nav-scroll {
  display: none;
}
</style>
