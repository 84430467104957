import { render, staticRenderFns } from "./TabsHead.vue?vue&type=template&id=d0c31544&scoped=true"
import script from "./TabsHead.vue?vue&type=script&lang=js"
export * from "./TabsHead.vue?vue&type=script&lang=js"
import style0 from "./TabsHead.vue?vue&type=style&index=0&id=d0c31544&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0c31544",
  null
  
)

export default component.exports